import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Col, Container, Input, Row, Spinner, Button, Modal, ModalBody, ModalHeader, ModalFooter, Label } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from 'react-data-table-component';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useTranslation } from "react-i18next";
import '@fortawesome/fontawesome-free/css/all.min.css';
//
// GraphQL Queries & Mutations
//

// Query for banners using the new data structure
const ADVERTISING_BANNERS_QUERY = gql`
  query MyQuery {
    advertising_banners(order_by: { position: asc }) {
      id
      image
      position
      service_provider {
        id
        company_name
        username
      }
    }
  }
`;

// Query for service providers to choose from when creating a new banner
const SERVICE_PROVIDERS_QUERY = gql`
  query GetServiceProviders {
    service_providers {
      id
      company_name
      username
    }
  }
`;

// Mutation to insert a new banner
const INSERT_BANNER_MUTATION = gql`
  mutation InsertBanner($image: String!, $service_provider_id: Int!, $position: Int!) {
    insert_advertising_banners_one(object: { image: $image, service_provider_id: $service_provider_id, position: $position }) {
      id
      image
      position
      service_provider {
        id
        company_name
        username
      }
    }
  }
`;

// Mutation to delete a banner
const DELETE_BANNER_MUTATION = gql`
  mutation DeleteBanner($id: Int!) {
    delete_advertising_banners_by_pk(id: $id) {
      id
    }
  }
`;

// Mutation to update banner position
const UPDATE_BANNER_POSITION_MUTATION = gql`
  mutation UpdateBannerPosition($id: Int!, $position: Int!) {
    update_advertising_banners_by_pk(pk_columns: { id: $id }, _set: { position: $position }) {
      id
      position
    }
  }
`;

//
// BannerManager Component
//

const BannerManager = () => {
  const { t } = useTranslation();
  document.title = "Banners";

  const [banners, setBanners] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Modal state for viewing banner image
  const [viewModalOpen, setViewModalOpen] = useState(false);
  // Modal state for creating a new banner
  const [newBannerModalOpen, setNewBannerModalOpen] = useState(false);
  // Modal state for editing a banner's position
  const [editPositionModalOpen, setEditPositionModalOpen] = useState(false);
  // Currently selected banner for various modals
  const [selectedBanner, setSelectedBanner] = useState(null);
  // For new banner creation: file and provider
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState('');
  // For updating position
  const [newPosition, setNewPosition] = useState('');

  // Fetch banners
  const { data, loading, refetch } = useQuery(ADVERTISING_BANNERS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setBanners(data.advertising_banners);
      setIsLoading(false);
    },
    onError: (err) => {
      console.error("Error loading banners:", err);
      setIsLoading(false);
    }
  });

  // Fetch service providers for new banner creation
  const { data: providersData } = useQuery(SERVICE_PROVIDERS_QUERY);

  // Mutation for inserting a new banner
  const [insertBanner] = useMutation(INSERT_BANNER_MUTATION, {
    onCompleted: () => {
      refetch();
    },
    onError: (err) => {
      console.error("Error inserting banner:", err);
    }
  });

  // Mutation for deleting a banner
  const [deleteBanner] = useMutation(DELETE_BANNER_MUTATION, {
    onCompleted: () => {
      // After deletion, reassign positions for the remaining banners
      reorderAfterDeletion();
    },
    onError: (err) => {
      console.error("Error deleting banner:", err);
    }
  });

  // Mutation for updating banner position
  const [updateBannerPosition] = useMutation(UPDATE_BANNER_POSITION_MUTATION, {
    onCompleted: () => {
      refetch();
    },
    onError: (err) => {
      console.error("Error updating banner position:", err);
    }
  });

  // --- Reordering functions ---

  // When moving one banner to a new position, adjust affected banners accordingly.
  const handleReorderBanner = async (banner, newPos) => {
    const oldPos = banner.position;
    if (newPos === oldPos) return; // No change

    // Get a sorted copy of current banners
    const sortedBanners = [...banners].sort((a, b) => a.position - b.position);

    if (newPos < oldPos) {
      // Moving upward: banners between newPos and oldPos - 1 shift one position down
      for (let b of sortedBanners) {
        if (b.id === banner.id) continue;
        if (b.position >= newPos && b.position < oldPos) {
          await updateBannerPosition({
            variables: { id: parseInt(b.id), position: b.position + 1 }
          });
        }
      }
    } else {
      // Moving downward: banners between oldPos + 1 and newPos shift one position up
      for (let b of sortedBanners) {
        if (b.id === banner.id) continue;
        if (b.position > oldPos && b.position <= newPos) {
          await updateBannerPosition({
            variables: { id: parseInt(b.id), position: b.position - 1 }
          });
        }
      }
    }
    // Finally, update the moved banner to the new position
    await updateBannerPosition({
      variables: { id: parseInt(banner.id), position: parseInt(newPos) }
    });
    await refetch();
  };

  // After deletion, reassign positions sequentially (1, 2, 3, ...)
  const reorderAfterDeletion = async () => {
    const { data: newData } = await refetch();
    const sortedBanners = [...newData.advertising_banners].sort((a, b) => a.position - b.position);
    for (let i = 0; i < sortedBanners.length; i++) {
      const desiredPosition = i + 1;
      if (sortedBanners[i].position !== desiredPosition) {
        await updateBannerPosition({
          variables: { id: parseInt(sortedBanners[i].id), position: desiredPosition }
        });
      }
    }
    await refetch();
  };

  // --- Modal handlers ---

  // View Modal: Display banner image in a larger view
  const openViewModal = (banner) => {
    setSelectedBanner(banner);
    setViewModalOpen(true);
  };
  const closeViewModal = () => {
    setViewModalOpen(false);
    setSelectedBanner(null);
  };

  // New Banner Modal: Open modal for creating a new banner
  const openNewBannerModal = () => {
    setSelectedFile(null);
    setSelectedProvider('');
    setNewBannerModalOpen(true);
  };
  const closeNewBannerModal = () => {
    setNewBannerModalOpen(false);
  };

  // Edit Position Modal: Open modal to update banner position
  const openEditPositionModal = (banner) => {
    setSelectedBanner(banner);
    setNewPosition(banner.position);
    setEditPositionModalOpen(true);
  };
  const closeEditPositionModal = () => {
    setEditPositionModalOpen(false);
    setSelectedBanner(null);
    setNewPosition('');
  };

  // --- Handlers for new banner creation ---

  // Handle file selection for new banner
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Create new banner: Upload file then insert banner with provider and position
  const handleCreateBanner = async () => {
    if (!selectedFile || !selectedProvider) {
      alert("Please select an image and a provider.");
      return;
    }
    const uploadUrl = process.env.REACT_APP_BACKEND_URL + "/api/upload/";
    const formData = new FormData();
    formData.append('myfile', selectedFile);
    formData.append('token', localStorage.getItem("token"));
    try {
      const response = await fetch(uploadUrl, {
        method: 'POST',
        headers: { authorization: localStorage.getItem("token") },
        body: formData,
      });
      if (!response.ok) {
        alert("Error uploading file: " + response.statusText);
        return;
      }
      const result = await response.json();
      if (result.url) {
        // Set new banner position as last position + 1
        const maxPosition = banners.reduce((max, b) => (b.position > max ? b.position : max), 0);
        const position = maxPosition + 1;
        await insertBanner({
          variables: {
            image: result.url,
            service_provider_id: parseInt(selectedProvider),
            position: position
          }
        });
        closeNewBannerModal();
      }
    } catch (err) {
      console.error("Error uploading file:", err);
    }
  };

  // --- Handlers for deletion & position update ---

  // Delete a banner and then reorder the remaining banners
  const handleDeleteBanner = async (banner) => {
    if (window.confirm("Are you sure you want to delete this banner?")) {
      await deleteBanner({ variables: { id: parseInt(banner.id) } });
      // reorderAfterDeletion is invoked in the delete mutation's onCompleted callback.
    }
  };

  // Update banner position using the reordering logic
  const handleUpdatePosition = async () => {
    if (!selectedBanner || newPosition === '') return;
    await handleReorderBanner(selectedBanner, newPosition);
    closeEditPositionModal();
  };

  // --- DataTable columns ---
  const columns = [
    // { name: "ID", selector: row => row.id, sortable: true, width: "60px" },
    {
      name: "Service Provider",
      selector: row => row.service_provider ? `${row.service_provider.company_name} (${row.service_provider.username})` : "N/A",
      sortable: true,
      wrap: true,
    },
    { name: "Position", selector: row => row.position, sortable: true },
    {
      name: "Image",
      cell: row => (
        <img 
          src={row.image} 
          alt="Banner" 
          style={{ width: "100px", cursor: "pointer" }} 
          onClick={() => openViewModal(row)} 
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Actions",
      cell: row => (
        <div>
          <Button color="info" size="sm" onClick={() => openEditPositionModal(row)}>
            <i className="fas fa-edit"></i> Position
          </Button>{" "}
          <Button color="danger" size="sm" onClick={() => handleDeleteBanner(row)}>
            <i className="fas fa-trash"></i>
          </Button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const customStyles = {
    cells: {
      style: {
        border: '0.5px solid #dee2e6',
      },
    },
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Banners")} pageTitle={t("Home")} />
          <Row className="mb-3">
            <Col>
              <Button color="primary" onClick={openNewBannerModal}>
                {t("New Banner")}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">{t("Banners")}</h5>
                </CardHeader>
                <CardBody>
                  <DataTable
                    striped
                    progressPending={isLoading}
                    dense
                    highlightOnHover
                    columns={columns}
                    data={banners}
                    pagination
                    customStyles={customStyles}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* View Banner Modal */}
      <Modal isOpen={viewModalOpen} toggle={closeViewModal} centered size="lg">
        <ModalHeader toggle={closeViewModal}>{t("View Banner")}</ModalHeader>
        <ModalBody className="text-center">
          {selectedBanner && (
            <img src={selectedBanner.image} alt="Banner" className="img-fluid" />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeViewModal}>{t("Close")}</Button>
        </ModalFooter>
      </Modal>

      {/* New Banner Modal */}
      <Modal isOpen={newBannerModalOpen} toggle={closeNewBannerModal} centered>
        <ModalHeader toggle={closeNewBannerModal}>{t("Create New Banner")}</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <Label for="bannerImage">{t("Banner Image")}</Label>
            <Input 
              type="file" 
              id="bannerImage" 
              accept="image/*" 
              onChange={handleFileChange} 
            />
          </div>
          <div className="mb-3">
            <Label for="providerSelect">{t("Select Provider")}</Label>
            <Input 
              type="select" 
              id="providerSelect" 
              value={selectedProvider} 
              onChange={(e) => setSelectedProvider(e.target.value)}
            >
              <option value="">{t("Select a Provider")}</option>
              {providersData &&
                providersData.service_providers &&
                providersData.service_providers.map(provider => (
                  <option key={provider.id} value={provider.id}>
                    {provider.company_name} ({provider.username})
                  </option>
                ))
              }
            </Input>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCreateBanner}>{t("Create")}</Button>
          <Button color="secondary" onClick={closeNewBannerModal}>{t("Cancel")}</Button>
        </ModalFooter>
      </Modal>

      {/* Edit Position Modal */}
      <Modal isOpen={editPositionModalOpen} toggle={closeEditPositionModal} centered>
        <ModalHeader toggle={closeEditPositionModal}>{t("Edit Banner Position")}</ModalHeader>
        <ModalBody>
          <Label for="positionInput">{t("New Position")}</Label>
          <Input 
            type="number" 
            id="positionInput" 
            value={newPosition} 
            onChange={(e) => setNewPosition(e.target.value)} 
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdatePosition}>{t("Save")}</Button>
          <Button color="secondary" onClick={closeEditPositionModal}>{t("Cancel")}</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default BannerManager;