import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useQuery, gql } from '@apollo/client';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from 'react-data-table-component';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'; // Import rsuite CSS

// Query to fetch completed appointments for top services
const TOP_SERVICES_QUERY = gql`
  query TopServices($service_provider_id: Int!, $start_date: timestamptz!, $end_date: timestamptz!) {
    appointments(
      where: {
        status: { _eq: "COMPLETED" }
        appointment_to_staff_to_service_relationship: {
          staff_to_service_relationship: { service: { service_provider_id: { _eq: $service_provider_id } } }
        }
        start_time: { _gte: $start_date, _lte: $end_date }
      }
    ) {
      appointment_to_staff_to_service_relationship {
        staff_to_service_relationship {
          service {
            name
            id
          }
        }
      }
    }
  }
`;

const TopServicesReport = () => {
  const serviceProviderId = parseInt(localStorage.getItem('opID'), 10);
  const [serviceData, setServiceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  // Handle date range change
  const handleDateChange = (value) => {
    setDateRange(value);
  };

  // Fetching top services by completed appointments
  const { data, loading, error, refetch } = useQuery(TOP_SERVICES_QUERY, {
    variables: {
      service_provider_id: serviceProviderId,
      start_date: dateRange[0].toISOString(),
      end_date: dateRange[1].toISOString(),
    },
    onCompleted: (data) => {
      if (data) {
        const services = data.appointments.reduce((acc, appointment) => {
          appointment.appointment_to_staff_to_service_relationship.forEach((relation) => {
            const serviceName = relation.staff_to_service_relationship.service.name;
        
            if (acc[serviceName]) {
              acc[serviceName].appointmentsCount += 1;
            } else {
              acc[serviceName] = {
                name: serviceName,
                appointmentsCount: 1,
              };
            }
          });
        
          return acc;
        }, {});
        
        // Sort services by the highest number of completed appointments
        const sortedServices = Object.values(services).sort((a, b) => b.appointmentsCount - a.appointmentsCount);
        setServiceData(sortedServices.slice(0, 10));
        setIsLoading(false);
      }
    },
    onError:(error)=>{
      console.log("error",error)

    },
  });

  // Data for the pie chart
  const chartData = {
    labels: serviceData.map((service) => service.name),
    datasets: [
      {
        data: serviceData.map((service) => service.appointmentsCount),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#E7E9ED', '#A8B3C5'],
      },
    ],
  };

  // Table columns
  const columns = [
    {
      name: 'Service',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Completed Appointments',
      selector: (row) => row.appointmentsCount,
      sortable: true,
    },
  ];
//hello
  const predefinedRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
    },
    {
      label: 'Yesterday',
      value: [new Date(new Date().setDate(new Date().getDate() - 1)), new Date(new Date().setDate(new Date().getDate() - 1))],
    },
    {
      label: 'Last 7 Days',
      value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
    },
    {
      label: 'Last 30 Days',
      value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
    },
    {
      label: 'All Time',
      value: [new Date('2020-01-01'), new Date()],
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Top Services" pageTitle="Home" />
          <Row>
            <Col md={12}>
              <h5>Select Date Range</h5>
              <DateRangePicker
                ranges={predefinedRanges}
                format="yyyy-MM-dd HH:mm:ss"
                value={dateRange}
                placeholder="Choose Range"
                style={{ width: 600 }}
                onChange={handleDateChange}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={6}>
              <Card>
                <CardBody>
                  <h5>Top 10 Services by Completed Appointments</h5>
                  <Pie data={chartData} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={12}>
              <Card>
                <CardBody>
                  <DataTable
                    title="Top 10 Services"
                    columns={columns}
                    data={serviceData}
                    progressPending={isLoading || loading}
                    pagination
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TopServicesReport;
