import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import {
  Card, CardBody, Col, Container, Row, Input, Label, Button, Alert, FormGroup
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { useTranslation } from "react-i18next";

const CREATE_STAFF_MUTATION = gql`
  mutation MyMutation($name: String!, $username: String!, $service_provider_id: Int!, $relationships: [staff_to_service_relationship_insert_input!]!) {
    insert_staff_one(
      object: {name: $name, username: $username, service_provider_id: $service_provider_id, staff_to_service_relationship: {data: $relationships}}
    ) {
      id
    }
  }
`;

const SERVICES_QUERY = gql`
  query MyQuery($service_provider_id: Int!) {
    services(where: {service_provider_id: {_eq: $service_provider_id}}) {
      id
      name
    }
  }
`;

const NewStaff = () => {
  const { t } = useTranslation();
  const serviceProviderId = parseInt(localStorage.getItem('opID'), 10);

  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [selectedServices, setSelectedServices] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  // Fetch all services of the provider
  const { data, loading: servicesLoading, error: servicesError } = useQuery(SERVICES_QUERY, {
    variables: { service_provider_id: serviceProviderId }
  });

  // Mutation to create staff
  const [createStaff, { loading: mutationLoading }] = useMutation(CREATE_STAFF_MUTATION, {
    onCompleted: () => {
      setShowSuccess(true);
      setName('');
      setUsername('');
      setSelectedServices(data?.services.map(service => service.id)); // Reset to all services selected
    },
    onError: () => {
      setShowError(true);
    }
  });

  // Initialize the selected services when data is loaded
  useEffect(() => {
    if (data) {
      setSelectedServices(data.services.map(service => service.id)); // Select all services by default
    }
  }, [data]);

  const handleServiceToggle = (serviceId) => {
    if (selectedServices.includes(serviceId)) {
      setSelectedServices(selectedServices.filter(id => id !== serviceId));
    } else {
      setSelectedServices([...selectedServices, serviceId]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (name && username && selectedServices.length > 0) {
        if (name && username ) {

      const relationships = selectedServices.map(serviceId => ({
        service_id: serviceId
      }));

      createStaff({
        variables: {
          name,
          username,
          service_provider_id: serviceProviderId,
          relationships
        }
      });
    } else {
      setShowError(true);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("New Staff")} pageTitle={t("Home")} />
          <Card>
            <CardBody>
              <Row className="mb-4">
                <Col md={12}>
                  {showSuccess && <Alert color="success">{t("Staff created successfully!")}</Alert>}
                  {showError && <Alert color="danger">{t("Please fill in all fields and select at least one service.")}</Alert>}
                </Col>
              </Row>
              <form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={2}>
                    <Label htmlFor="nameInput">{t("Staff Name")}</Label>
                  </Col>
                  <Col md={4}>
                    <Input
                      type="text"
                      id="nameInput"
                      placeholder={t("Enter staff name")}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={2}>
                    <Label htmlFor="usernameInput">{t("Username")}</Label>
                  </Col>
                  <Col md={4}>
                    <Input
                      type="text"
                      id="usernameInput"
                      placeholder={t("Enter username")}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={2}>
                    <Label>{t("Assign Services")}</Label>
                  </Col>
                  <Col md={6}>
                    {servicesLoading && <p>{t("Loading services...")}</p>}
                    {servicesError && <p>{t("Error loading services.")}</p>}
                    {data && data.services.map(service => (
                      <FormGroup check key={service.id}>
                        <Input
                          type="checkbox"
                          checked={selectedServices.includes(service.id)}
                          onChange={() => handleServiceToggle(service.id)}
                        />
                        <Label check>{service.name}</Label>
                      </FormGroup>
                    ))}
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="text-end">
                    <Button type="submit" color="primary" disabled={mutationLoading || servicesLoading}>
                      {mutationLoading ? t("Creating...") : t("Create Staff")}
                    </Button>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewStaff;
