import React from 'react';



// Inline styles to avoid CSS conflicts
const styles = {
  progressBarContainer: {
    position: 'relative',
    width: '100%',
    backgroundColor: '#e2e8f0',
    borderRadius: '10px',
    height: '10px',
    marginTop: '20px'
  },
  progressBar: (progress) => ({
    width: `${progress}%`,
    backgroundColor: '#3b82f6',
    height: '100%',
    borderRadius: '10px',
    transition: 'width 0.3s ease'
  }),
  milestoneContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '5px'
  },
  milestone: {
    textAlign: 'center',
    flex: '1',
    position: 'relative'
  },
  milestoneDot: (isCompleted) => ({
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    border: '2px solid',
    borderColor: isCompleted ? '#3b82f6' : '#cbd5e1',
    backgroundColor: isCompleted ? '#3b82f6' : '#fff',
    color: isCompleted ? '#fff' : '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    margin: '0 auto'
  }),
  milestoneLabel: {
    fontSize: '12px',
    // marginTop: '5px',
    width: '80px',
    textAlign: 'center',

  }
};

// ProgressBar component - the main progress bar background and the filled portion
const ProgressBar = ({ progress }) => {
  return (
    <div style={styles.progressBarContainer}>
      <div style={styles.progressBar(progress)}></div>
    </div>
  );
};

// Milestone component - individual milestones with checkmarks and captions
const Milestone = ({ label, percentage, progress }) => {
  const isCompleted = progress >= percentage;

  return (
    <div style={styles.milestone}>
      <div style={styles.milestoneDot(isCompleted)}>
        {isCompleted ? '✓' : ''}
      </div>
      <div style={styles.milestoneLabel}>{label}</div>
    </div>
  );
};

// MilestoneProgressBar component - the container that holds the progress bar and milestones
const MilestoneProgressBar = ({ progress,milestones }) => {
  return (
    <div>
      <ProgressBar progress={progress} />
      <div style={styles.milestoneContainer}>
        {milestones.map((milestone) => (
          <Milestone
            key={milestone.label}
            label={milestone.label}
            percentage={milestone.percentage}
            progress={progress}
          />
        ))}
      </div>
    </div>
  );
};

export default MilestoneProgressBar;