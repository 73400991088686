import React, { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const TICKETS_QUERY = gql`
  query TicketsQuery($_eq: Int!) {
    tickets_users(order_by: { updated_at: desc }, where: { operator_id: { _eq: $_eq } }) {
      id
      description
      created_at
      operator_id
      status
      subject
      updated_at
      author_user {
        username
        first_name
        last_name
      }
      appointment {
        id
        start_time
        created_at
        appointment_to_staff_to_service_relationship {
          staff_to_service_relationship {
            service {
              name
              service_provider {
                company_name
              }
            }
            staff {
              name
            }
          }
        }
      }
    }
  }
`;

const Ticket = () => {
  const { t } = useTranslation();
  const [TheTickets, setTheTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const { refetch: refetch_tickets } = useQuery(TICKETS_QUERY, {
    variables: { _eq: parseInt(localStorage.getItem("opID"), 10) },
    onCompleted: (data) => {
      const the_tickets = data.tickets_users;
      setTheTickets(the_tickets);
    },
    onError: (err) => {
      console.log(err);
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch_tickets();
  }, [refetch_tickets]);

  const handleOpenAppointmentDetails = (ticket, e) => {
    e.stopPropagation(); // Prevent row click
    setSelectedTicket(ticket);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTicket(null);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
            id="the_main_grid"
            style={{ backgroundColor: "white", margin: "0px", width: "100%" }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "16px 16px 0px 16px",
              }}
            >
              <Typography color="black" variant="h4">
                {t("Tickets")}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: "16px" }}>
              <TableContainer
                style={{ maxHeight: "600px", overflowY: "scroll" }}
                component={Paper}
              >
                <Table sx={{ minWidth: 500 }} stickyHeader size="small">
                  <TableHead style={{ backgroundColor: "black" }}>
                    <TableRow className="UsersTable">
                      <TableCell align="center">{t("Ticket ID")}</TableCell>
                      <TableCell align="center">{t("Last update")}</TableCell>
                      <TableCell align="center">{t("Subject")}</TableCell>
                      <TableCell align="center">{t("From")}</TableCell>
                      <TableCell align="center">{t("Appointment Details")}</TableCell>
                      <TableCell align="center">{t("Status")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {TheTickets.map((row, i) => (
                      <TableRow
                        key={i}
                        style={{
                          cursor: "pointer",
                          backgroundColor: row.status === "Closed" ? "green" : "inherit",
                        }}
                        onClick={() => {
                          sessionStorage.setItem("ticket_id", row.id);
                          window.location.href = "/#/supportUser/specificticketUser";
                        }}
                      >
                        <TableCell
                          style={{ color: row.status === "Closed" ? "white" : "inherit" }}
                          align="center"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell
                          style={{ color: row.status === "Closed" ? "white" : "inherit" }}
                          align="center"
                        >
                          {moment(row.updated_at).format("DD/MM/YYYY hh:mm")}
                        </TableCell>
                        <TableCell
                          style={{ color: row.status === "Closed" ? "white" : "inherit" }}
                          align="center"
                        >
                          {row.subject}
                        </TableCell>
                        <TableCell
                          style={{ color: row.status === "Closed" ? "white" : "inherit" }}
                          align="center"
                        >
                          {row.author_user
                            ? `${row.author_user.username} (${row.author_user.first_name} ${row.author_user.last_name})`
                            : "N/A"}
                        </TableCell>
                        <TableCell
                          style={{ color: row.status === "Closed" ? "white" : "inherit" }}
                          align="center"
                        >
                          {row.appointment ? (
                            <Button
                              variant="contained"
                              onClick={(e) => handleOpenAppointmentDetails(row, e)}
                            >
                              {t("Appointment Details")}
                            </Button>
                          ) : (
                            <Button variant="contained" disabled>
                              {t("No Appointment")}
                            </Button>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ color: row.status === "Closed" ? "white" : "inherit" }}
                          align="center"
                        >
                          {row.status}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </div>

      {/* Appointment Details Modal */}
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>{t("Appointment Details")}</DialogTitle>
        <DialogContent dividers>
          {selectedTicket && selectedTicket.appointment ? (
            <div>
              <Typography variant="body1">
                <strong>{t("Appointment ID")}:</strong> {selectedTicket.appointment.id}
              </Typography>
              <Typography variant="body1">
                <strong>{t("Start Time")}:</strong>{" "}
                {moment(selectedTicket.appointment.start_time).format("MMMM Do YYYY, h:mm A")}
              </Typography>
              <Typography variant="body1">
                <strong>{t("Created At")}:</strong>{" "}
                {moment(selectedTicket.appointment.created_at).format("MMMM Do YYYY, h:mm A")}
              </Typography>
              <Typography variant="body1">
                <strong>{t("Services")}:</strong>
              </Typography>
              <ul>
                {selectedTicket.appointment.appointment_to_staff_to_service_relationship.map((rel, index) => (
                  <li key={index}>
                    {rel.staff_to_service_relationship.service.name} by {rel.staff_to_service_relationship.staff.name} (
                    {t("Provider")}: {rel.staff_to_service_relationship.service.service_provider.company_name})
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <Typography variant="body1">{t("No appointment details available.")}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>{t("Close")}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Ticket;