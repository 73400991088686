import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle, Form, Input,Label } from 'reactstrap';

//import images
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

//import Components
// import SearchOption from '../Components/Common/SearchOption';
import LanguageDropdown from '../Components/Common/LanguageDropdown';
// import WebAppsDropdown from '../Components/Common/WebAppsDropdown';
// import MyCartDropdown from '../Components/Common/MyCartDropdown';
// import FullScreenDropdown from '../Components/Common/FullScreenDropdown';
import NotificationDropdown from '../Components/Common/NotificationDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';
// import LightDark from '../Components/Common/LightDark';

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
    
    
    const checkTokenValidity = () => {
        const token = localStorage.getItem("token");
        if (!token) return;
      
        fetch(process.env.REACT_APP_BACKEND_URL + "/api/frontend/whoami/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "token": token }),
        })
        .then((response) => {
            if (!response.ok) {
                window.location.href = "/#/login";
            }
        })
        .catch((error) => console.error("Error checking token validity:", error));
    };

    // ✅ Ensure interval runs only once
    useEffect(() => {
        const tokenValidityChecker = setInterval(checkTokenValidity, 10000);
        
        // ✅ Cleanup to prevent multiple intervals
        return () => clearInterval(tokenValidityChecker);
    }, []); // Empty dependency array ensures it runs only once on mount

    
    const [search, setSearch] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const [operator, setOperator] = useState(true);

    const toogleSearch = () => {
        setSearch(!search);
    };

    const submitSearch = () => {
        console.log("searchword",searchWord)
        console.log("operator",operator)
        if(operator){
            if(window.location.hash.startsWith("#/onlineusers")){
                window.location.href = `/#/onlineusers?${searchWord}`;
                window.location.reload()
            }else{
                window.location.href = `/#/onlineusers?${searchWord}`;

            }



        }else{
            if(window.location.hash.startsWith("#/shops")){
                window.location.href = `/#/shops?${searchWord}`;
                window.location.reload()
            }else{
                window.location.href = `/#/shops?${searchWord}`;

            }
        }
    };
    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;

        if (windowSize > 767)
            document.querySelector(".hamburger-icon").classList.toggle('open');

        //For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === "horizontal") {
            document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
        }

        //For collapse vertical menu
        if (document.documentElement.getAttribute('data-layout') === "vertical") {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }

        //Two column menu
        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
        }
    };
    return (
        <React.Fragment>
            <header id="page-topbar" className={headerClass}>
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">

                            <div className="navbar-brand-box horizontal-logo">
                                <Link to="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="17" />
                                    </span>
                                </Link>
                            </div>

                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>


                            {/* <SearchOption /> */}
                        </div>

                        <div className="d-flex align-items-center">

                            {/* <Dropdown isOpen={search} toggle={toogleSearch} className="d-md-none topbar-head-dropdown header-item"> */}
                            <Dropdown isOpen={search} toggle={toogleSearch} className="topbar-head-dropdown header-item">

                                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                                    <i className="bx bx-search fs-22"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                    <Form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search ..."
                                                    aria-label="Recipient's username" value={searchWord} onChange={(e)=>{setSearchWord(e.target.value)}}/>
                                                <button className="btn btn-primary" type="button" onClick={submitSearch}><i
                                                    className="mdi mdi-magnify"></i></button>
                                            </div>
                                        </div>
                                        <div key="operator" className="form-check form-check-inline">
          {/* <Input className="form-check-input" type="radio" name="currency" id={`currency-${currency.id}`} onChange={(e)=>{setSelectedCurrency(e.target.value)}}  value={currency.name} /> */}
          <Input className="form-check-input" type="radio" name="operator" id="operator" onChange={(e)=>{setOperator(true)}} checked={operator} value={operator} />

          <Label className="form-check-label" htmlFor="operator">User</Label>
        </div>
        <div key="operator" className="form-check form-check-inline">
          {/* <Input className="form-check-input" type="radio" name="currency" id={`currency-${currency.id}`} onChange={(e)=>{setSelectedCurrency(e.target.value)}}  value={currency.name} /> */}
          <Input className="form-check-input" type="radio" name="shop" id="shop" onChange={(e)=>{setOperator(false)}} checked={!operator} value={!operator} />

          <Label className="form-check-label" htmlFor="shop">Provider</Label>
        </div>
                                    </Form>
                                </DropdownMenu>
                            </Dropdown>

                            {/* LanguageDropdown */}
                            <LanguageDropdown />

                            {/* WebAppsDropdown */}
                            {/* <WebAppsDropdown /> */}

                            {/* MyCartDropdwon */}
                            {/* <MyCartDropdown /> */}

                            {/* FullScreenDropdown */}
                            {/* <FullScreenDropdown /> */}

                            {/* Dark/Light Mode set */}
                            {/* <LightDark
                                layoutMode={layoutModeType}
                                onChangeLayoutMode={onChangeLayoutMode}
                            /> */}

                            {/* NotificationDropdown */}
                            {/* <NotificationDropdown /> */}

                            {/* ProfileDropdown */}
                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;