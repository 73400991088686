import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  InputGroup,
  InputGroupText
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useMutation, gql } from "@apollo/client";

import { useNavigate } from 'react-router-dom';

import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Autocomplete
} from "@react-google-maps/api";


const INSERT_SERVICE_PROVIDER_MUTATION = gql`
  mutation InsertServiceProvider($input: [service_providers_insert_input!]!) {
    insert_service_providers(objects: $input) {
      returning {
        id
      }
    }
  }
`;

const INSERT_STAFF_MUTATION = gql`
  mutation InsertStaff($input: [staff_insert_input!]!) {
    insert_staff(objects: $input) {
      affected_rows
    }
  }
`;




const NewServiceProvider = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    phone: "",
    contact_email: "-",
    business_email: "-",
    description: "",
    address_street: "",
    address_city: "",
     company_name:"",
    // address_zip_code: "",
    latitude: "",
    longitude: "",
    primary_staff_name: "", // New field for primary staff name
    categories: {
      Hair: true,
      Barber: true,
      Nails: true,
      Tattoo: true,
      Spa: true,
    },
  });
  const [errors, setErrors] = useState({});
  const [autocomplete, setAutocomplete] = useState(null);

  const [insertServiceProvider, { loading, error }] = useMutation(INSERT_SERVICE_PROVIDER_MUTATION, {
    onCompleted: data => {
      // alert("Service provider created successfully!");
      setFormData({
        username: "",
        email: "",
        password: "",
        phone: "",
        contact_email: "-",
        business_email: "-",
        description: "",
        address_street: "",
        address_city: "",
        company_name: "",
        // address_zip_code: "",
        latitude: "",
        longitude: "",
        categories: {
          Hair: true,
          Barber: true,
          Nails: true,
          Tattoo: true,
          Spa: true,
        },
      });
    },
    onError: err => {
      alert("Failed to create service provider. Error: " + err.message);
    }
  });


  const [insertStaff, { loadingstaff, errorstaff }] = useMutation(INSERT_STAFF_MUTATION, {
    onCompleted: data => {
      // alert("Staff created successfully!");
    },
    onError: err => {
      alert("Failed to create staff . Error: " + err.message);
    }
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const usernameRegex = /^[a-zA-Z][a-zA-Z0-9_]{2,19}$/; // Starts with letter, 3-20 chars, only letters, numbers, underscore
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  
    Object.entries(formData).forEach(([key, value]) => {
      if (!value) {
        newErrors[key] = "This field is required";
      } else if (key === "email" && !emailRegex.test(value)) {
        newErrors[key] = "Invalid email format";
      } else if (key === "username" && !usernameRegex.test(value)) {
        newErrors[key] = "Username must be 3-20 characters, start with a letter, and contain only letters, numbers, or underscores.";
      } else if (key === "password" && !passwordRegex.test(value)) {
        newErrors[key] = "Password must be at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character.";
      }
    });
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const containerStyle = {
    width: "100%",
    height: "400px"
  };
  
  const center = {
    lat: 34.684181, // Default Latitude for Nicosia
    lng: 33.032112 // Default Longitude for Nicosia
  };
  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: "AIzaSyDjK440-o0tGhaDZ2hInxSvZWm4KxxJdjU",
  //   libraries: ["places"]
  // });


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
  
    // Separate primary staff name to avoid GraphQL errors
    const { primary_staff_name, categories,...providerInput } = {
      ...formData,
      address_coordinates: `${formData.latitude}, ${formData.longitude}`,
      parent_operator_id: 1,
    };
    // Convert categories object -> array of { name: "..." }
  const selectedCategoriesArr = Object.entries(categories)
  .filter(([_, isChecked]) => isChecked)
  .map(([catName]) => ({ name: catName }));

// Now attach the array to the input
providerInput.categories = selectedCategoriesArr;
    delete providerInput.latitude;
    delete providerInput.longitude;
  
    try {
      // 1️⃣ Insert Service Provider
      const newProviderInput = {
        ...providerInput, // Keep existing values
        is_enabled: true, // Ensure this field is included
      };
      
      const providerResponse = await insertServiceProvider({ 
        variables: { input: [newProviderInput] } 
      });  
      if (providerResponse.data.insert_service_providers.returning.length > 0) {
        const providerId = providerResponse.data.insert_service_providers.returning[0].id;
  
        // 2️⃣ Insert Primary Staff (if provided)
        if (primary_staff_name) {
          const randomNumber = Math.floor(100000 + Math.random() * 900000); // Generate a 6-digit random number
          const username = `${randomNumber}${primary_staff_name}`.toLowerCase(); // Concatenate and convert to lowercase
        
          await insertStaff({
            variables: { 
              input: [{ username:username, name: primary_staff_name, service_provider_id: providerId }] 
            }
          });
        }
  
        // 3️⃣ Send Welcome Email to the Provider
  
        alert("Service provider and primary staff created successfully!");
        await handleSendServiceProviderEmail(formData.email, formData.username, formData.password);

        navigate(`/shopDetails/${providerId}`);

        // setFormData({ ...formData, primary_staff_name: "" }); // Reset the form
      }
  
    } catch (error) {
      console.error("Error creating provider/staff:", error);
      alert("Failed to create service provider or staff.");
    }
  };
  const handleSendServiceProviderEmail = async (email, username, password) => {
    const token = localStorage.getItem("token");
    const companyName = "PlanMate"; // Replace with actual name
    const loginURL = "https://planmate-provider.thetensortech.com"; // Login link
  
    const emailSubject = "Your Service Provider Account is Ready!";
    const emailMessage = `Dear ${username},<br><br>
      Your service provider account has been successfully created. You can now log in using the credentials below:<br><br>
      <strong>Username:</strong> ${username}<br>
      <strong>Password:</strong> ${password}<br><br>
      Click here to log in: <a href="${loginURL}">${loginURL}</a><br><br>
      Please make sure to change your password upon first login.<br><br>
      Best Regards,<br>
      ${companyName}`;
  
    const requestBody = {
      token: token,
      recipient_address: email,
      email_subject: emailSubject,
      email_message: emailMessage,
      // company_name: companyName,
    };
  
    const url = process.env.REACT_APP_BACKEND_URL + '/api/backoffice/send-html-email/';
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        console.log(`Welcome email sent to ${email}`);
      } else {
        console.warn(`Failed to send welcome email to ${email}`);
      }
    } catch (error) {
      console.error(`Error sending welcome email to ${email}:`, error);
    }
  };
  const categoryNames = ["Hair", "Barber", "Nails", "Tattoo", "Spa"];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("New Service Provider")} pageTitle={t("Service Providers")} />
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row >
                  <Col md={4}>
                    <Label htmlFor="username">{t("Username")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-user-fill"></i>
                      </InputGroupText>
                      <Input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        invalid={!!errors.username}
                      />
                      <FormFeedback>{t(errors.username)}</FormFeedback>
                    </InputGroup>
                  </Col>
                  <Col md={4}>
                    <Label htmlFor="company_name">{t("Company Name")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-user-fill"></i>
                      </InputGroupText>
                      <Input
                        type="text"
                        id="company_name"
                        name="company_name"
                        value={formData.company_name}
                        onChange={handleChange}
                        invalid={!!errors.company_name}
                      />
                      <FormFeedback>{t(errors.company_name)}</FormFeedback>
                    </InputGroup>
                  </Col>

                  
                  <Col md={4}>
                    <Label htmlFor="password">{t("Password")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-lock-password-fill"></i>
                      </InputGroupText>
                      <Input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        invalid={!!errors.password}
                      />
                      <FormFeedback>{t(errors.password)}</FormFeedback>
                    </InputGroup>
                  </Col>
                </Row>
                <Row style={{"marginTop":"20px"}}>
                  <Col md={6}>
                    <Label htmlFor="email">{t("Email")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-mail-fill"></i>
                      </InputGroupText>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        invalid={!!errors.email}
                      />
                      <FormFeedback>{t(errors.email)}</FormFeedback>
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Label htmlFor="phone">{t("Phone")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-phone-fill"></i>
                      </InputGroupText>
                      <Input
                        type="text"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        invalid={!!errors.phone}
                      />
                      <FormFeedback>{t(errors.phone)}</FormFeedback>
                    </InputGroup>
                  </Col>
                </Row>

                {/* <Row>
                  <Col md={6}>
                    <Label htmlFor="contact_email">{t("Contact Email")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-mail-fill"></i>
                      </InputGroupText>
                      <Input
                        type="email"
                        id="contact_email"
                        name="contact_email"
                        value={formData.contact_email}
                        onChange={handleChange}
                        invalid={!!errors.contact_email}
                      />
                      <FormFeedback>{t(errors.contact_email)}</FormFeedback>
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Label htmlFor="business_email">{t("Business Email")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-mail-fill"></i>
                      </InputGroupText>
                      <Input
                        type="email"
                        id="business_email"
                        name="business_email"
                        value={formData.business_email}
                        onChange={handleChange}
                        invalid={!!errors.business_email}
                      />
                      <FormFeedback>{t(errors.business_email)}</FormFeedback>
                    </InputGroup>
                  </Col>
                </Row> */}
                <Row style={{"marginTop":"20px"}}>
                <Col md={12}>
                    <Label htmlFor="description">{t("Description")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-file-text-fill"></i>
                      </InputGroupText>
                      <Input
                        type="text"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        invalid={!!errors.description}
                      />
                      <FormFeedback>{t(errors.description)}</FormFeedback>
                    </InputGroup>
                  </Col>
                </Row>
                {/* <Row style={{"marginTop":"20px"}}>
                  <Col md={3}>
                    <Label htmlFor="address_street">{t("Street")}</Label>
                    <Input
                      type="text"
                      id="address_street"
                      name="address_street"
                      value={formData.address_street}
                      onChange={handleChange}
                      invalid={!!errors.address_street}
                    />
                    <FormFeedback>{t(errors.address_street)}</FormFeedback>
                  </Col>
                  <Col md={3}>
                    <Label htmlFor="address_city">{t("City")}</Label>
                    <Input
                      type="text"
                      id="address_city"
                      name="address_city"
                      value={formData.address_city}
                      onChange={handleChange}
                      invalid={!!errors.address_city}
                    />
                    <FormFeedback>{t(errors.address_city)}</FormFeedback>
                  </Col>
                  <Col md={3}>
                    <Label htmlFor="address_zip_code">{t("Zip Code")}</Label>
                    <Input
                      type="text"
                      id="address_zip_code"
                      name="address_zip_code"
                      value={formData.address_zip_code}
                      onChange={handleChange}
                      invalid={!!errors.address_zip_code}
                    />
                    <FormFeedback>{t(errors.address_zip_code)}</FormFeedback>
                  </Col>
                </Row> */}

                <Row style={{ marginTop: "20px" }}>
  <Col md={6}>
    <Label htmlFor="address_street">{t("Street")}</Label>
    <Autocomplete
  onLoad={(autoCompleteInstance) => setAutocomplete(autoCompleteInstance)}
  onPlaceChanged={() => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        const lat = location.lat();
        const lng = location.lng();
        setFormData((prev) => ({
          ...prev,
          latitude: lat,
          longitude: lng,
          address_street: place.formatted_address,
          address_city: place.address_components.find((comp) =>
            comp.types.includes("locality")
          )?.long_name || ""
        }));
      }
    }
  }}
>
  <input
    type="text"
    className="form-control"
    id="address_street"
    value={formData.address_street || ""}
    onChange={(e) =>
      setFormData({ ...formData, address_street: e.target.value })
    }
    placeholder="Start typing address..."
  />
</Autocomplete>
  </Col>
  {/* <Col md={6}>
    <Label htmlFor="address_city">{t("City")}</Label>
    <Input
      type="text"
      id="address_city"
      name="address_city"
      value={formData.address_city}
      onChange={handleChange}
      invalid={!!errors.address_city}
    />
    <FormFeedback>{t(errors.address_city)}</FormFeedback>
  </Col> */}
</Row>

<Row style={{ marginTop: "20px" }}>
  <Col sm={12}>
    <Label>{t("Select Location on Map")}</Label>


  <GoogleMap
    mapContainerStyle={containerStyle}
    center={formData.latitude && formData.longitude
      ? { lat: parseFloat(formData.latitude), lng: parseFloat(formData.longitude) }
      : center}
    zoom={14}
    onClick={(event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      setFormData((prev) => ({
        ...prev,
        latitude: lat,
        longitude: lng
      }));

      // Reverse Geocoding to get the address
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        { location: { lat, lng } },
        (results, status) => {
          if (status === "OK" && results[0]) {
            setFormData((prev) => ({
              ...prev,
              address_street: results[0].formatted_address,
              address_city: results[0].address_components.find((comp) =>
                comp.types.includes("locality")
              )?.long_name || ""
            }));
          }
        }
      );
    }}
  >
    {formData.latitude && formData.longitude && (
      <Marker
        position={{
          lat: parseFloat(formData.latitude),
          lng: parseFloat(formData.longitude)
        }}
      />
    )}
  </GoogleMap>

  </Col>
</Row>
<Row style={{"marginTop":"20px"}}>
                  <Col md={6}>
                    <Label htmlFor="latitude">{t("Latitude")}</Label>
                    <Input
                      disabled
                      type="text"
                      id="latitude"
                      name="latitude"
                      value={formData.latitude}
                      onChange={handleChange}
                      invalid={!!errors.latitude}
                    />
                    <FormFeedback>{t(errors.latitude)}</FormFeedback>
                  </Col>
                  <Col md={6}>
                    <Label htmlFor="longitude">{t("Longitude")}</Label>
                    <Input
                    disabled
                      type="text"
                      id="longitude"
                      name="longitude"
                      value={formData.longitude}
                      onChange={handleChange}
                      invalid={!!errors.longitude}
                    />
                    <FormFeedback>{t(errors.longitude)}</FormFeedback>
                  </Col>
                </Row>
<Col md={6}>
  <Label htmlFor="primary_staff_name">{t("Primary Staff Name")}</Label>
  <InputGroup>
    <InputGroupText>
      <i className="ri-user-fill"></i>
    </InputGroupText>
    <Input
      type="text"
      id="primary_staff_name"
      name="primary_staff_name"
      value={formData.primary_staff_name}
      onChange={handleChange}
      invalid={!!errors.primary_staff_name}
    />
    <FormFeedback>{t(errors.primary_staff_name)}</FormFeedback>
  </InputGroup>
</Col>
<Row className="mb-3" style={{"marginTop":"20px"}}>
  <Col xs={12}>
    <Label style={{"marginRight":"20px"}}>Categories</Label>
    {categoryNames.map((catName) => (
      <div className="form-check form-check-inline" key={catName}>
        <Input
          className="form-check-input"
          type="checkbox"
          id={catName}
          checked={formData.categories[catName]}
          onChange={(e) => {
            const { checked } = e.target;
            setFormData((prev) => ({
              ...prev,
              categories: {
                ...prev.categories,
                [catName]: checked
              }
            }));
          }}
        />
        <Label className="form-check-label" for={catName}>
          {catName}
        </Label>
      </div>
    ))}
  </Col>
</Row>
                <div className="text-end mt-4">
                  <Button color="primary" type="submit" disabled={loading}>
                    {t("Create")}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewServiceProvider;
