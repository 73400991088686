import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import {
  Card, CardBody, Col, Container, Row, Input, Label, Button, Alert, InputGroup, InputGroupText
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { useTranslation } from "react-i18next";

const CREATE_SERVICE_MUTATION = gql`
  mutation CreateService($service_provider_id: Int!, $price: Int!, $name: String!, $duration: Int!, $description: String!, $is_popular: Boolean!, $color: String!, $staffIds: [staff_to_service_relationship_insert_input!]!) {
    insert_services_one(
      object: {description: $description,is_enabled: true, duration: $duration, name: $name, price: $price, service_provider_id: $service_provider_id, is_popular: $is_popular, color: $color, staff_to_service_relationship: {data: $staffIds}}
    ) {
      id
    }
  }
`;

const STAFF_QUERY = gql`
query GetStaff($service_provider_id: Int!) {
  staff(where: {service_provider_id: {_eq: $service_provider_id}}) {
    id
    name
  }
}
`;

export const NewService = ({serviceProviderId,onSuccess}) => {
  const { t } = useTranslation();
  // const serviceProviderId = parseInt(localStorage.getItem('opID'), 10);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [duration, setDuration] = useState('');
  const [description, setDescription] = useState('');
  const [color, setColor] = useState('#000000'); // Default color
  const [isPopular, setIsPopular] = useState(false); // Is Popular checkbox
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const { loading: staffLoading, data: staffData } = useQuery(STAFF_QUERY, {
    variables: { service_provider_id: serviceProviderId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.staff) {
        setSelectedStaff(data.staff.map(staff => staff.id)); // Select all staff by default
      }
    },
    onError: (error) => {
      console.error('Error fetching staff:', error.message);
    }
  });

  const [createService, { loading }] = useMutation(CREATE_SERVICE_MUTATION, {
    onCompleted: () => {
      setShowSuccess(true);
      setName('');
      setPrice('');
      setDuration('');
      setDescription('');
      setColor('#000000');
      setIsPopular(false);
      setSelectedStaff(staffData.staff.map(staff => staff.id)); // Reset staff selection
      onSuccess();
        },
    onError: () => {
      setShowError(true);
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name && price && duration && description && selectedStaff.length > 0) {
      const staffIds = selectedStaff.map(staffId => ({
        staff_id: staffId,
      }));
      createService({
        variables: {
          service_provider_id: serviceProviderId,
          name,
          price: parseInt(price, 10),
          duration: parseInt(duration, 10),
          description,
          color,
          is_popular: isPopular,
          staffIds
        }
      });
    } else {
      setShowError(true);
    }
  };

  const handleStaffChange = (staffId) => {
    if (selectedStaff.includes(staffId)) {
      setSelectedStaff(selectedStaff.filter(id => id !== staffId));
    } else {
      setSelectedStaff([...selectedStaff, staffId]);
    }
  };

  return (
    <React.Fragment>
        <Container fluid>
          <Card>
            <CardBody>
              <Row className="mb-4">
                <Col md={12}>
                  {showSuccess && <Alert color="success">{t("Service created successfully!")}</Alert>}
                  {showError && <Alert color="danger">{t("Please fill in all fields correctly.")}</Alert>}
                </Col>
              </Row>
              <form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={4}>
                    <Label htmlFor="nameInput">{t("Service Name")}</Label>
                  </Col>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="nameInput"
                      placeholder={t("Enter service name")}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <Label htmlFor="priceInput">{t("Price")}</Label>
                  </Col>
                  <Col md={8}>
                    <InputGroup>
                      <InputGroupText>€</InputGroupText>
                      <Input
                        type="number"
                        id="priceInput"
                        placeholder={t("Enter price")}
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        required
                        min="0"
                        step="1"
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <Label htmlFor="durationInput">{t("Duration")}</Label>
                  </Col>
                  <Col md={8}>
                    <Input
                      type="select"
                      id="durationInput"
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                      required
                    >
                      <option value="" disabled>{t("Select duration")}</option>
                      <option value="15">15 {t("minutes")}</option>
                      <option value="30">30 {t("minutes")}</option>
                      <option value="45">45 {t("minutes")}</option>
                      <option value="60">1 {t("hour")}</option>
                      <option value="75">1 {t("hour")} 15 {t("minutes")}</option>
                      <option value="90">1 {t("hour")} 30 {t("minutes")}</option>
                      <option value="105">1 {t("hour")} 45 {t("minutes")}</option>
                      <option value="120">2 {t("hours")}</option>
                    </Input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <Label htmlFor="descriptionInput">{t("Description")}</Label>
                  </Col>
                  <Col md={6}>
                    <Input
                      type="textarea"
                      id="descriptionInput"
                      placeholder={t("Enter service description")}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <Label>{t("Popular Service")}</Label>
                  </Col>
                  <Col md={8}>
                    <Input
                      type="checkbox"
                      checked={isPopular}
                      onChange={(e) => setIsPopular(e.target.checked)}
                    /> {t("Mark as popular")}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <Label>{t("Color")}</Label>
                  </Col>
                  <Col md={8}>
                    <Input
                      type="color"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <Label>{t("Assign Staff")}</Label>
                  </Col>
                  <Col md={6}>
                    {staffLoading ? <p>{t("Loading staff...")}</p> : (
                      staffData.staff.map(staff => (
                        <div key={staff.id}>
                          <Input
                            type="checkbox"
                            checked={selectedStaff.includes(staff.id)}
                            onChange={() => handleStaffChange(staff.id)}
                          /> {staff.name}
                        </div>
                      ))
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="text-end">
                    <Button type="submit" color="primary" disabled={loading}>
                      {loading ? t("Creating...") : t("Create Service")}
                    </Button>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Container>
    </React.Fragment>
  );
};

export default NewService;
