import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Breadcrumb, BreadcrumbItem, Card, CardHeader, CardBody, Col, Container, Input, Label, Row, Button, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Link, useParams } from 'react-router-dom';
import classnames from "classnames";
import { useQuery, useMutation, gql } from '@apollo/client';
import { useCreateLog } from "../../Components/Common/functions";
import ServicesTab from '../Services/services';
import Reviews from '../Reviews/reviews';
import ProfilePage from '../GeneralShop/profile';
import ShopAppointments from '../ShopAppointments/shopAppointments';
import { GoogleMap, Marker, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import MilestoneProgressBar from "./progressMilestoneBar.js"






const PROVIDER_QUERY = gql`
query MyQuery($id: Int!) {
  service_providers_by_pk(id: $id) {
    username
    company_name
    password
    email
    description
    address_zip_code
    address_street
    address_state
    address_coordinates
    address_city
    phone
    categories
    is_enabled
    manual_accept_appointment
  }
}
`;

const COMPLETION_STATUS_QUERY = gql`
query MyQuery($id: Int!) {
  service_providers_by_pk(id: $id) {
    is_enabled
    services(where: {is_enabled: {_eq: true}}) {
      id
    }
    opening_hours
  }
}
`;


const EDIT_PROVIDER_MUTATION = gql`
mutation MyMutation($id: Int!, $username: String!, $company_name: String!, $password: String!, $email: String!, $description: String!,  $address_street: String!, $address_state: String!, $address_coordinates: String!, $address_city: String!, $phone: String!, $categories: jsonb, $is_enabled: Boolean = false, $manual_accept_appointment: Boolean = false) {
  update_service_providers_by_pk(
    pk_columns: {id: $id}
    _set: {company_name: $company_name, username: $username, password: $password, email: $email, description: $description, address_street: $address_street, address_state: $address_state, address_coordinates: $address_coordinates, address_city: $address_city, phone: $phone, categories: $categories, is_enabled: $is_enabled, manual_accept_appointment: $manual_accept_appointment}
  ) {
    id
  }
}

`;

const DELETE_PROVIDER_MUTATION = gql`
mutation MyMutation($id: Int!) {
  delete_service_providers_by_pk(id: $id) {
    id
  }
}
`;


const ServiceProviderDetails = () => {
  const { t } = useTranslation();
  const { createLog } = useCreateLog();
  const { userId } = useParams();
  const [activeTab, setActiveTab] = useState("1");
  const [providerDetails, setProviderDetails] = useState({
    // other fields: ...
    categoriesObject: {
      Hair: false,
      Barber: false,
      Nails: false,
      Tattoo: false,
      Spa: false
    },
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [mutationSuccess, setMutationSuccess] = useState(false);
  const [modal, setModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Add this state variable
  const [milestones, setMilestones] = useState([
    { label: 'Details Entered', percentage: 25 },
    { label: 'Opening Hours Entered', percentage: 50 },
    { label: 'Services Entered', percentage: 75 },
    { label: 'Completed', percentage: 100 }
  ]); // Add this state variable
  const [statusPercentage, setStatusPercentage] = useState(0); // Add this state variable

  const toggleModal = () => setModal(!modal);
  const containerStyle = {
    width: "100%",
    height: "400px"
  };

  const updateMilestones = (data) => {
    if (!data || !data.service_providers_by_pk) return 25;
    
    const { is_enabled, services, opening_hours } = data.service_providers_by_pk;
    const hasServices = services && services.length > 0;
    const hasOpeningHours = opening_hours && Object.values(opening_hours).some(day => day.length > 0);
    
    let updatedMilestones = [
      { label: 'Details Entered', percentage: 25 },
      { label: 'Services Entered', percentage: 50 },
      { label: 'Opening Hours Entered', percentage: 75 },
      { label: 'Completed', percentage: 100 }
    ];
    
    let currentPercentage = 25;
    
    if (hasServices && hasOpeningHours) {
      currentPercentage = is_enabled ? 100 : 75;
      if (!is_enabled) {
        updatedMilestones[3].label = 'Enable the shop to go public';
      }
    } else if (hasServices) {
      currentPercentage = 50;
    } else if ( hasOpeningHours) {
      currentPercentage = 50;
      let tmpmilestonelabel=updatedMilestones[1].label
      updatedMilestones[1].label=updatedMilestones[2].label
      updatedMilestones[1].percentage=50
      updatedMilestones[2].label=tmpmilestonelabel
      updatedMilestones[2].percentage = 75;
    }
    
    setMilestones(updatedMilestones);
    return currentPercentage;
  };
  
  const center = {
    lat: 34.684181, // Default Latitude for Nicosia
    lng: 33.032112 // Default Longitude for Nicosia
  };
  console.log('Component initialized');
  const categoryNames = ["Hair", "Barber", "Nails", "Tattoo", "Spa"];
  function arrayToCategoriesObject(arrayOfCats) {
    // If it's not an array (e.g. it's {}), treat it as []
    if (!Array.isArray(arrayOfCats)) {
      arrayOfCats = [];
    }
  
    // Start with everything false
    const obj = { Hair: false, Barber: false, Nails: false, Tattoo: false, Spa: false };
  
    arrayOfCats.forEach(item => {
      if (obj.hasOwnProperty(item.name)) {
        obj[item.name] = true;
      }
    });
    return obj;
  }
  function categoriesObjectToArray(obj) {
    // Convert { Hair: true, Barber: false, ... } back to [{ name: 'Hair' }, ...]
    return Object.entries(obj)
      .filter(([_, isChecked]) => isChecked)
      .map(([catName]) => ({ name: catName }));
  }
  const { loading, error, data, refetch } = useQuery(PROVIDER_QUERY, {
    variables: { id: parseInt(userId) },
    onCompleted: (data) => {
      
      const details = data.service_providers_by_pk;
      console.log("details",details)
      const coordinates = details.address_coordinates
      ? details.address_coordinates.split(",").map(Number)
      : [center.lat, center.lng];
      setProviderDetails({
        ...details,
        // details.categories is presumably an array of objects
        categoriesObject: arrayToCategoriesObject(details.categories),
        latitude: coordinates[0],
        longitude: coordinates[1]
      });



    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    }
  });


  const { loading:loadingCOMP, error:errorCOMP, data:dataCOMP, refetch:refetchCOMP } = useQuery(COMPLETION_STATUS_QUERY, {
    variables: { id: parseInt(userId) },
    onCompleted: (data) => {
      let statusPerc=updateMilestones(data)
      console.log("statusPerc",statusPerc)
      setStatusPercentage(statusPerc)
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    }
  });

  const [editProviderMutation] = useMutation(EDIT_PROVIDER_MUTATION, {
    onCompleted: (data) => {
      console.log('Edit mutation completed:', data);
      refetchCOMP({ variables: { id: parseInt(userId) } })
      createLog(parseInt(userId), "service_provider", "Details Edited", true);
      setMutationSuccess(true);
    },
    onError: (error) => {
      console.error('Error in edit mutation:', error);
      createLog(parseInt(userId), "service_provider", "Details Edited", false);
    },
  });

  const [deleteProviderMutation] = useMutation(DELETE_PROVIDER_MUTATION, {
    onCompleted: (data) => {
      console.log('Delete mutation completed:', data);
      createLog(parseInt(userId), "service_provider", "Provider Deleted", true);
      window.location.href = "/#/serviceProviders";
    },
    onError: (error) => {
      console.error('Error in delete mutation:', error);
      createLog(parseInt(userId), "service_provider", "Provider Deleted", false);
    }
  });

  const handleSaveDetailsClick = async () => {
    // address_zip_code
    //|| !address_zip_code
    // address_city
    // || !address_city
    const { username,company_name, password, email, description, address_street, address_state,address_city, address_coordinates, phone,categoriesObject, is_enabled, manual_accept_appointment } = providerDetails;
    const finalCategories = categoriesObjectToArray(categoriesObject);

    if (!username ||!company_name || !password || !email || !description  || !address_street ||  !address_coordinates  || !phone || finalCategories.length<=0) {
      alert("All fields are required.");
      return;
    }
    try {
      await editProviderMutation({
        variables: {
          id: parseInt(userId),
          username,
          company_name,
          password,
          email,
          description,
          // address_zip_code,
          address_street,
          address_state,
          address_coordinates,
          address_city,
          phone,
          categories: finalCategories,
          is_enabled, // Add this
          manual_accept_appointment // Add this
        },
      });
      setMutationSuccess(true);
    } catch (error) {
      console.error('Error in handleSaveDetailsClick:', error);
    }
  };

  const handleDeleteProviderClick = async () => {
    try {
      await deleteProviderMutation({ variables: { id: parseInt(userId) } });
      toggleModal();
    } catch (error) {
      console.error('Error in handleDeleteProviderClick:', error);
    }
  };

  useEffect(() => {
    if (mutationSuccess) {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
      setMutationSuccess(false);
    }
  }, [mutationSuccess]);


  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        setProviderDetails((prev) => ({
          ...prev,
          latitude: location.lat(),
          longitude: location.lng(),
          address_street: place.formatted_address,
          address_city: place.address_components.find((comp) =>
            comp.types.includes("locality")
          )?.long_name || ""
        }));
      }
    }
  };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    setProviderDetails((prev) => ({
      ...prev,
      latitude: lat,
      longitude: lng,
      address_coordinates: `${lat},${lng}`, // ✅ Now updates coordinates correctly
    }));

    // Reverse Geocoding to get the address
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results[0]) {
        setProviderDetails((prev) => ({
          ...prev,
          address_street: results[0].formatted_address,
          address_city: results[0].address_components.find((comp) =>
            comp.types.includes("locality")
          )?.long_name || ""
        }));
      }
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  console.log('Rendering component with details:', providerDetails);

  return (
    <React.Fragment>

      <div className="page-content">

        <Container fluid>

          <BreadCrumb title={t("Service Providers")} pageTitle={t("Home")} />
          {showSuccessMessage && (
            <div className="alert alert-success text-center" role="alert">
              Success!
            </div>
          )}

          <Row>
          <MilestoneProgressBar progress={statusPercentage} milestones={milestones}/>

            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">{t("Service Provider")} - {providerDetails.username}</h5>

                </CardHeader>
                <CardBody>
                <Nav tabs className="nav-tabs mb-3">
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "1" })} onClick={() => { toggle("1"); }}>
                        {t("Details")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "2" })} onClick={() => { toggle("2"); }}>
                        {t("Services")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "3" })} onClick={() => { toggle("3"); }}>
                        {t("Appointments")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "4" })} onClick={() => { toggle("4"); }}>
                        {t("Reviews")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "5" })} onClick={() => { toggle("5"); }}>
                        {t("General")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                      <Card>
                        <CardBody>
                          <form action="#">
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="usernameInput" className="form-label">{t("Username")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input disabled type="text" className="form-control" id="usernameInput" value={providerDetails.username || ''} onChange={(e) => setProviderDetails({ ...providerDetails, username: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="company_nameInput" className="form-label">{t("Company Name")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="company_nameInput" value={providerDetails.company_name || ''} onChange={(e) => setProviderDetails({ ...providerDetails, company_name: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="passwordInput" className="form-label">{t("Password")}</Label>
                              </Col>
                              <Col lg={6}>
                                <div className="input-group">
                                  <Input type={showPassword ? "text" : "password"} className="form-control" id="passwordInput" value={providerDetails.password || ''} onChange={(e) => setProviderDetails({ ...providerDetails, password: e.target.value })} />
                                  <button type="button" className="btn btn-outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? "Hide" : "Show"}
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="emailInput" className="form-label">{t("Email")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="email" className="form-control" id="emailInput" value={providerDetails.email || ''} onChange={(e) => setProviderDetails({ ...providerDetails, email: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="descriptionInput" className="form-label">{t("Description")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="descriptionInput" value={providerDetails.description || ''} onChange={(e) => setProviderDetails({ ...providerDetails, description: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="phoneInput" className="form-label">{t("Phone")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="phoneInput" value={providerDetails.phone || ''} onChange={(e) => setProviderDetails({ ...providerDetails, phone: e.target.value })} />
                              </Col>
                            </Row>


                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="addressInput" className="form-label">{t("Address")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Autocomplete
                                  onLoad={(autoCompleteInstance) => setProviderDetails((prev) => ({ ...prev, autocomplete: autoCompleteInstance }))}
                                  onPlaceChanged={() => {
                                    if (providerDetails.autocomplete) {
                                      const place = providerDetails.autocomplete.getPlace();
                                      if (place.geometry) {
                                        const location = place.geometry.location;
                                        setProviderDetails((prev) => ({
                                          ...prev,
                                          latitude: location.lat(),
                                          longitude: location.lng(),
                                          address_coordinates: `${location.lat()},${location.lng()}`, // ✅ Now updates coordinates correctly
                                          address_street: place.formatted_address,
                                          address_city: place.address_components.find((comp) =>
                                            comp.types.includes("locality")
                                          )?.long_name || ""
                                        }));
                                      }
                                    }
                                  }}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="addressInput"
                                    value={providerDetails.address_street || ""}
                                    placeholder="Start typing address..."
                                    onChange={(e) =>
                                      setProviderDetails({ ...providerDetails, address_street: e.target.value })
                                    }
                                  />
                                </Autocomplete>
                              </Col>
                            </Row>

                            <Row>
                              <Col sm={12}>
                                <Label>{t("Select Location on Map")}</Label>
                                {
                                  <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={
                                      providerDetails.latitude && providerDetails.longitude
                                        ? { lat: providerDetails.latitude, lng: providerDetails.longitude }
                                        : center
                                    }
                                    zoom={14}
                                    onClick={handleMapClick}

                                  >
                                    {providerDetails.latitude && providerDetails.longitude && (
                                      <Marker position={{ lat: providerDetails.latitude, lng: providerDetails.longitude }} />
                                    )}
                                  </GoogleMap>
                                }
                              </Col>
                            </Row>
                            <Row>

  <Col md={3}>
    <Label htmlFor="coordinatesInputLat" className="form-label">{t("Coordinates")}</Label>
  </Col>
  <Col lg={3}>
    <Input
    disabled
      type="text"
      className="form-control"
      id="coordinatesInputLat"
      placeholder="Latitude"
      value={providerDetails.address_coordinates ? providerDetails.address_coordinates.split(",")[0] : ""}
      onChange={(e) => {
        const coords = providerDetails.address_coordinates
          ? providerDetails.address_coordinates.split(",")
          : ["", ""];
        coords[0] = e.target.value;
        setProviderDetails({
          ...providerDetails,
          address_coordinates: coords.join(","),
        });
      }}
    />
  </Col>
  <Col lg={3}>
    <Input
    disabled
      type="text"
      className="form-control"
      id="coordinatesInputLon"
      placeholder="Longitude"
      value={providerDetails.address_coordinates ? providerDetails.address_coordinates.split(",")[1] : ""}
      onChange={(e) => {
        const coords = providerDetails.address_coordinates
          ? providerDetails.address_coordinates.split(",")
          : ["", ""];
        coords[1] = e.target.value;
        setProviderDetails({
          ...providerDetails,
          address_coordinates: coords.join(","),
        });
      }}
    />
  </Col>
</Row>
                            {/* <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="addressCityInput" className="form-label">{t("City")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="addressCityInput" value={providerDetails.address_city || ''} onChange={(e) => setProviderDetails({ ...providerDetails, address_city: e.target.value })} />
                              </Col>
                            </Row> */}
                            {/* <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="addressStateInput" className="form-label">{t("State")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="addressStateInput" value={providerDetails.address_state || ''} onChange={(e) => setProviderDetails({ ...providerDetails, address_state: e.target.value })} />
                              </Col>
                            </Row> */}
                            {/* <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="addressZipCodeInput" className="form-label">{t("Zip Code")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="addressZipCodeInput" value={providerDetails.address_zip_code || ''} onChange={(e) => setProviderDetails({ ...providerDetails, address_zip_code: e.target.value })} />
                              </Col>
                            </Row>*/}
                            <Row className="mb-3"> 
  <Col md={3}>
    <Label className="form-label">Categories</Label>
  </Col>
  <Col lg={6}>
    {categoryNames.map((catName) => (
      <div className="form-check form-check-inline" key={catName}>
        <Input
          className="form-check-input"
          type="checkbox"
          id={catName}
          checked={providerDetails.categoriesObject?.[catName] ?? false}
          onChange={(e) => {
            const isChecked = e.target.checked;
            setProviderDetails((prev) => ({
              ...prev,
              categoriesObject: {
                ...prev.categoriesObject,
                [catName]: isChecked
              }
            }));
          }}
        />
        <Label className="form-check-label" for={catName}>
          {catName}
        </Label>
      </div>
    ))}
  </Col>
</Row>
<Row className="mb-3">
  <Col md={3}>
    <Label htmlFor="manualAcceptAppointmentInput" className="form-label">{t("Manual Accept Appointment")}</Label>
  </Col>
  <Col lg={6}>
    <Input 
      type="checkbox" 
      className="form-check-input" 
      id="manualAcceptAppointmentInput" 
      checked={providerDetails.manual_accept_appointment || false} 
      onChange={(e) => setProviderDetails({ ...providerDetails, manual_accept_appointment: e.target.checked })} 
    />
  </Col>
</Row>
<Row className="mb-3">
  <Col md={3}>
    <Label htmlFor="isEnabledInput" className="form-label">{t("Is Enabled")}</Label>
  </Col>
  <Col lg={6}>
    <Input 
      type="checkbox" 
      className="form-check-input" 
      id="isEnabledInput" 
      checked={providerDetails.is_enabled || false} 
      onChange={(e) => setProviderDetails({ ...providerDetails, is_enabled: e.target.checked })} 
    />
  </Col>
</Row>


                            <div className="text-end">
                              <Button type="button" className="btn btn-primary" onClick={handleSaveDetailsClick}>{t("Save")}</Button>
                            </div>
                            {showSuccessMessage && (
            <div className="alert alert-success text-center" role="alert">
              Success!
            </div>
          )}
                          </form>
                        </CardBody>
                      </Card>
                      <div className="text-end">
                        {/* <Button type="submit" className="btn btn-light" onClick={toggleModal}>{t("Delete this Provider")}</Button> */}
                        <Modal isOpen={modal} toggle={toggle}>
                          <ModalHeader toggle={toggleModal}>Confirm Deletion</ModalHeader>
                          <ModalBody>
                            Are you sure you want to delete this provider? This action cannot be undone.
                          </ModalBody>
                          <ModalFooter>
                            <Button color="primary" onClick={handleDeleteProviderClick}>Yes, Delete</Button>{' '}
                            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                    <ServicesTab serviceProviderId={parseInt(userId)} refetchCOMP={refetchCOMP} />                    </TabPane>
                    <TabPane tabId="4">
                    <Reviews serviceProviderId={parseInt(userId)}/>
                    </TabPane>
                    <TabPane tabId="5">
                    <ProfilePage serviceProviderId={parseInt(userId)}/>
                    </TabPane>
                    <TabPane tabId="3">
                    <ShopAppointments serviceProviderId={parseInt(userId)}/>
                    </TabPane>
                    
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServiceProviderDetails;